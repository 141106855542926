<template>
  <div class="landing-page">
    <div class="intro">Can you tell if an article is from Babylon Bee by its title?</div>
    <button
      class="btn-start"
      v-on:click="goToNextArticle"
    >
      Play
    </button>
  </div>
</template>

<script>
import getRandomSource from '@/lib/getRandomSource';

const title = 'Is it Babylon Bee?';

export default {
  beforeRouteEnter(to, from, next) {
    document.title = title;
    next();
  },
  beforeRouteUpdate(to, from, next) {
    document.title = title;
    next();
  },
  methods: {
    goToNextArticle() {
      const nextHash = getRandomSource();
      this.$router.push(`/article/${nextHash}`);
    }
  }
}
</script>

<style scoped>
  .btn-start {
    background-color: #ffed4a;
    border: 0.02em solid #FFFFFF;
    border-color: #FFED4A;
    color: #000000;
    font-weight: 900;
    font-size: 5em;
  }

  .intro {
    font-size: 1.5em;
    font-weight: 900;
  }

  .landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
</style>
