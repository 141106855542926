const sources = require('@/../data/sources');

const pages = sources.sources.map(({ hash }) => hash);

function getRandomSource(sourceToExclude) {
  const index = parseInt(Math.random() * pages.length);
  const nextPage = pages[index];
  if(sourceToExclude === nextPage) {
    return getRandomSource(sourceToExclude);
  }

  return nextPage;
}

export default getRandomSource;
